import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import "../styles/about.scss"

export default function About() {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "bangkok.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const { fluid } = data.file.childImageSharp

  return (
    <Layout className="about-container">
      <SEO title="About Us" />

      <ul className="about__list">
        <li>Marketing consultant based in Bangkok</li>
        <hr />
        <li>
          Over <span>12</span> years experience
        </li>
        <hr />
        <li>Friendly, skilled and hard-working staff</li>
      </ul>
      <div className="about__bgline" />
      <Img fluid={fluid} className="about__img" />
    </Layout>
  )
}
